import { v4 as uuidv4 } from "uuid";
import { H1, H2, P, Input, Button, Icon, H3, ProgressIndicator, Card, Drawer } from "@dnb/eufemia";
import { microphone as Microphone, chatbot as Chatbot, cloud as Cloud  } from '@dnb/eufemia/icons'
import { useState, useRef, useEffect } from "react";
import { BASE_API_URL } from './config';
import { DNB_API_URL } from './config';
import '@dnb/eufemia/style/core'
import '@dnb/eufemia/style/themes/ui'
import '@dnb/eufemia/extensions/payment-card/style'
import PaymentCard, {
  getCardData,
} from '@dnb/eufemia/extensions/payment-card'


interface UserData {
    userId: string,
    user: User
}

interface User {
    id: string,
    firstName: string,
    name: string,
    programme: string
}

interface AccountsData {
    userId: string,
    accounts: Account[]
}

interface Account {
    id: string,
    name: string,
    number: string,
    balance: number
}

// interface InvestmentsData {
//     id: string,
//     holdings: Holding[]
// }

// interface Holding {
//     id: string,
//     name: string,
//     type: string,
//     ticker: string,
//     shares: number,
//     amountNOK: number
// }

const CHAT_HISTORY_LENGTH = 10;

export function MobileChatFirst() {

    // Chat
    const [inputValue, setInputValue] = useState("");
    const [allMessages, setAllMessages] = useState<ChatMessage[]>([])
    const [scroll, setScroll] = useState([""])
    const [isLoading, setIsLoading] = useState(false);
    const messageEndRef =  useRef<HTMLDivElement>(null);
    const [modalIsActive, setModalIsActive] = useState(false)
   

    // API Data
    const [user, setUser] = useState<User>();
    const [accounts, setAccounts] = useState<Account[]>();
    const [investments, setInvestments] = useState<Holding[]>();

    // Workflow
    const [activeWorkflow, setActiveWorkflow] = useState(false)
    const [workflow, setWorkflow] = useState<Workflow>()
    const [workflowStepIndex, setworkflowStepIndex] = useState<number>()

    // useEffect(() => {
    //     if (messageEndRef.current) {
    //       messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    //     }
    //   }, [scroll]);

    // useEffect(() => {
    //     if (user == undefined) {
    //         fetch(DNB_API_URL+"/user", {
    //             headers: {"DNB-USERID": "1234"},
    //         })
    //         .then((response) => response.json())
    //         .then((data: UserData) => {
    //             if (data.user != undefined)
    //                 setUser(data.user)
    //         })
    //     }

    //     if (accounts == undefined) {
    //         fetch(DNB_API_URL+"/accounts", {
    //             headers: {"DNB-USERID": "1234"},
    //         })
    //         .then((response) => response.json())
    //         .then((data: AccountsDto) => {
    //             if (data.accounts != undefined)
    //                 setAccounts(data.accounts)
    //         })
    //     }

    //     if (investments == undefined) {
    //         fetch(DNB_API_URL+"/investments", {
    //             headers: {"DNB-USERID": "1234"},
    //         })
    //         .then((response) => response.json())
    //         .then((data: InvestmentsDto) => {
    //             if (data.holdings != undefined)
    //                 setInvestments(data.holdings)
    //         })
    //     }
    // }, [])

    useEffect(() => {
        // Updated workflow step index:

        if (workflow !== undefined && workflowStepIndex !== undefined ) {

            console.log("workflowStepIndex: " + workflowStepIndex + ", workflow.steps.length: " + workflow.steps.length)

            if (workflow.steps != undefined) {
                if (workflow.steps[workflowStepIndex].type == 5) {
                  console.log("URL: " + DNB_API_URL + workflow.steps[workflowStepIndex].api)
                    fetch(DNB_API_URL + workflow.steps[workflowStepIndex].api, {
                        headers: {"X-BANK-USER": "FRR"},
                    })
                    .then((response) => response.json())
                    .then((data) => {
                      console.log(data)
                      const tmp0 = allMessages;
                      console.log("allMessages.length in sendChatMessageToAPI 0: " + tmp0.length);
                      const msgId = uuidv4()
                      setAllMessages(
                        [
                          {
                            id: msgId,
                            user: "assistant",
                            text: JSON.stringify(data),
                            createdAt: getCurrentTime(),
                            actionType: 5,
                            actionName: workflow.steps[workflowStepIndex].api
                          },

                          ...tmp0,
                        ]
                      );
                    })
                }

                if (workflow.steps.length = 1) {
                  setActiveWorkflow(false)
                }
            }
        }

    }, [workflowStepIndex])

    useEffect(() => {
      if (activeWorkflow)  {
        console.log("Workflow active")
      }
      else {
        console.log("Workflow not active")
        setWorkflow(undefined)
        setworkflowStepIndex(undefined)
      }
    }, [activeWorkflow])
  

    const getCurrentTime = () => {
        var today = new Date();
        var curTime = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        return curTime;
    }

    const handleRagSendMessage = () => {
        const content = inputValue.trim();
        if (content) {
          let fullChatHistory: ChatHistoryItem[] =  []
          allMessages.forEach(msg => {
            fullChatHistory.push({"role": msg.user, "content": msg.text})
          });
    
          while (fullChatHistory.length > 2 * CHAT_HISTORY_LENGTH) {
            fullChatHistory.shift()
          }
          // }
          const message = {
            name: "user",
            type: "user_message" as const,
            output: content,
          };
          sendChatMessageToAPI(message.output, true, fullChatHistory)
          setInputValue("");
        }
      };


  const sendChatMessageToAPI = async (message: string, rag: boolean = false, fullChatHistory: ChatHistoryItem[]) => {
    if (!modalIsActive)
        // setModalIsActive(true)

    setIsLoading(true)
    let api = `${BASE_API_URL}/chat/1234`;
    if (rag) {
      api = `${BASE_API_URL}/ragChat/1234`;
    }

    const tmp0 = allMessages;
    console.log("allMessages.length in sendChatMessageToAPI 0: " + tmp0.length);
    const msgId = uuidv4()
    setAllMessages(
      [
        {
          id: msgId,
          user: "user",
          text: message,
          createdAt: getCurrentTime()
        },
        ...tmp0,
      ]
    );

    let response = await fetch(api, {
      method: "POST",
      body: JSON.stringify({
        prompt: message,
        chatHistory: fullChatHistory
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
    let data = await response.json();
    if (data.llmResponse === "-1") {

      setIsLoading(false)
      console.log(data);
      setActiveWorkflow(true)
      loadWorkflow(data.id)

    } else {
      const tmp = allMessages;
      console.log("allResponses.length in sendChatMessageToAPI 1: " + tmp.length);
      setAllMessages(
        [
          {
            id: data.id,
            user: "assistant",
            text: data.llmResponse,
            createdAt: data.answeredAt,
          },
          {
            id: msgId,
            user: "user",
            text: data.prompt,
            createdAt: data.createdAt,
          },
          ...tmp,
        ]
      );
      setScroll((prev) => [...prev, `New message ${prev.length + 1}`])
      setIsLoading(false)
    }
  }

  const loadWorkflow = (id: string) => {
    setIsLoading(true)
    let api = `${BASE_API_URL}/workflow/${id}`;
    fetch(api, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data: Workflow) => {
        console.log("wid: " + data.id)
        setIsLoading(false)
        setWorkflow(data)
        setworkflowStepIndex(0)
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

    const storeValueFromWorkflow = () => {
    }

    const renderAccount = (account: Account) => {
        return (
            <div>
                <H3>{account.name}, balance: {account.balance} NOK</H3>
            </div>
        )
    }
    const renderHoldings = (holding: Holding) => {
        return (
            <div>
                <H3>{holding.name}, balance: {holding.amountNOK} NOK</H3>
            </div>
        )
    }

    

  const renderMessage = (message: ChatMessage) => {

    if (message.actionType != undefined && message.actionName != undefined) {
      if (message.actionType == 5) {
        if (message.actionName == "/cards/list" || message.actionName == "/cards/block") {

          const cardDto: Card[] = JSON.parse(message.text)
          const cards = cardDto

          return (
            <div key={message.id} >
              <div className="flex pr-10 items-start space-x-2 m-auto pt-2">
                <div className="flex-1">
                  <small className="text-xs text-dnb-black-55">
                    {message.createdAt.substring(0,5)}
                  </small>
                  <P><Icon icon={Cloud} title="Cloud Icon" /></P>
                  {
                    cards.map((card: Card) => {
                      return (
                        <div  key={card.id}>
                          <PaymentCard
                            product_code="VP5" 
                            card_number="1234567887654321" />
                        </div>
                      )
                    })
                  }

                </div>
                {/* <div ref={messageEndRef}></div> */}
              </div>
            </div>
          )
        }
        else if (message.actionName == "/accounts/list") {
          const accountsDto: AccountsDto = JSON.parse(message.text)
          const accounts = accountsDto.accounts

          return (
            <div key={message.id} >
              <div className="flex pr-10 items-start space-x-2 m-auto pt-2">
                <div className="flex-1">
                  <small className="text-xs text-dnb-black-55">
                    {message.createdAt.substring(0,5)}
                  </small>
                  <P><Icon icon={Cloud} title="Cloud Icon" /></P>
                  {
                    accounts.map((account: Account) => {
                      return (
                        <div  key={account.id}>
                          <Card>
                            <P>Kontonavn: {account.name}</P>
                            <P>Kontonummer: {account.number}</P>
                            <P>Saldo: {account.balance} NOK</P>
                          </Card>
                        </div>
                      )
                    })
                  }

                </div>
                {/* <div ref={messageEndRef}></div> */}
              </div>
            </div>
          )
          
        }
        else if (message.actionName == "/loans") {
          const loansDto: LoansDto = JSON.parse(message.text)
          const loans = loansDto.loans

          return (
            <div key={message.id} >
              <div className="flex pr-10 items-start space-x-2 m-auto pt-2">
                <div className="flex-1">
                  <small className="text-xs text-dnb-black-55">
                    {message.createdAt.substring(0,5)}
                  </small>
                  <P><Icon icon={Cloud} title="Cloud Icon" /></P>
                  {
                    loans.map((loan: Loan) => {
                      return (
                        <div  key={loan.id}>
                          <Card>
                            <P>Lån: {loan.name}</P>
                            <P>Lånenummer: {loan.number}</P>
                            <P>Restbeløp lån: {loan.balance} kr</P>
                          </Card>
                        </div>
                      )
                    })
                  }

                </div>
                {/* <div ref={messageEndRef}></div> */}
              </div>
            </div>
          )
          
        }
        else if (message.actionName == "/investments") {
          const investementsDto: InvestmentsDto = JSON.parse(message.text)
          const holdings = investementsDto.holdings

          return (
            <div key={message.id} >
              <div className="flex pr-10 items-start space-x-2 m-auto pt-2">
                <div className="flex-1">
                  <small className="text-xs text-dnb-black-55">
                    {message.createdAt.substring(0,5)}
                  </small>
                  <P><Icon icon={Cloud} title="Cloud Icon" /></P>
                  {
                    holdings.map((holding: Holding) => {
                      return (
                        <div  key={holding.id}>
                          <Card>
                            <P>{holding.name}</P>
                            <P>Ticker: {holding.ticker}</P>
                            <P>Verdi: {holding.amountNOK} kr</P>
                            <P>Antall: {holding.shares}</P>
                          </Card>
                        </div>
                      )
                    })
                  }

                </div>
                {/* <div ref={messageEndRef}></div> */}
              </div>
            </div>
          )
          
        }
      }
    }
    else {

      return (
        <div key={message.id} >
          {
            message.user === "user" ?

            <div className="grid place-content-end flex items-start space-x-2 m-auto pl-1">
              <div>
                  <small className="text-xs text-dnb-black-55">{message.createdAt.substring(0,5)}</small>
                  <div>
                    <div className="inline-block px-4 py-2 bg-white rounded border border-1 max-w-96 md:max-w-prose lg:max-w-screen-lg">
                      <P>{message.text}</P>
                    </div>
                  </div>
              </div>
              <div ref={messageEndRef}></div>
            </div>
            :

              <div className="flex pr-10 items-start space-x-2 m-auto pt-2">
                <div className="flex-1">
                  <small className="text-xs text-dnb-black-55">
                    {message.createdAt.substring(0,5)}
                  </small>
                  <P><Icon icon={Chatbot} title="Robot Icon." /> {message.text}</P>
                </div>
                {/* <div ref={messageEndRef}></div> */}
              </div>
          }
        </div>
      );
    }
  };
  

    return (
        <div className="p-5 bg-dnb-black-3">
            <div className="text-dnb-oceangreen py-2">
              <H1>Hjem</H1>
            </div>
            <div className="bg-white rounded py-5 pr-2 grid grid-cols-9">
                <div className="text-dnb-summergreen bottom-0 m-auto">
                <H2><Icon icon={Chatbot} title="Robot Icon." /></H2>
                </div>
                <div className="bg-dnb-black-8 py-3 px-2 rounded-t rounded-r col-span-8">
                  <P>Hei {user?.firstName}, hva kan vi gjøre for deg i dag?</P> 
               </div>
            </div>
           
          <div className="bg-white p-2 rounded-b">
            <div className="flex items-center space-x-2">
                  <Input
                        size="medium"
                        placeholder="Skriv her..."
                        stretch={true}
                        value={inputValue}
                        on_change={({ value }) => {
                        setInputValue(value)
                        }}
                        onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            if (activeWorkflow)
                            storeValueFromWorkflow();
                            else  
                            handleRagSendMessage();
                        }
                        }}
                  />
                
                  <Button
                    title="Small sized button with add icon"
                    icon={Microphone}
                    size="medium"
                    on_click={()=>{console.log("her kommer en funksjon som du kan snakke til")}}
                  />
{/* <<<<<<< HEAD */}
                  <Button text="Send" on_click={handleRagSendMessage}/>
              </div>
              <div className="overflow-auto p-10" style={{maxHeight: "60vh"}}>
                        {
                        isLoading?
                            <ProgressIndicator className="text-center"/>
                            :
                            <div/>
                        }
                        <div className="">
                        {
                            allMessages.map((message) => renderMessage(message))
                        }
                        </div>
                    </div>
              </div>

            {/* <Modal openState={modalIsActive} omit_trigger_button>
            <div className=" flex flex-col" >
                <div className="sticky bottom-0 border-t p-4 bg-white dark:bg-gray-800 mt-20 ml-5 mr-5 mb-10 p-5">
                    <div className="flex">
                        <Button className="items-end" on_click={deactivateModal}><Icon icon={close} />Lukk</Button>
                    </div>
                    <H3>DNB Assistent </H3>
                    <div className="overflow-scroll p-5">
                        {
                        isLoading?
                            <ProgressIndicator className="text-center"/>
                            :
                            <div/>
                        }
                        <div className="space-y-4">
                        {
                            allMessages.map((message) => renderMessage(message))
                        }
                        </div>
                    </div>
                    <div className="sticky bottom-0 border-t p-4 bg-white dark:bg-gray-800">
                        <div className="flex items-center space-x-2">
                            <Input
                                size="medium"
                                placeholder="Skriv her..."
                                stretch={true}
                                value={inputValue}
                                on_change={({ value }) => {
                                setInputValue(value)
                                }}
                                onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    if (activeWorkflow)
                                    storeValueFromWorkflow();
                                    else  
                                    handleRagSendMessage();
                                }
                                }}
                            />
                            <Button text="Send"
                                on_click={handleRagSendMessage}/>
                        </div>
                    </div>
                </div>  */}
                 
                {/* <Drawer
                  onOpen={handleRagSendMessage}
                  openState={modalIsActive}
                  triggerAttributes={{
                    text: 'Send',
                  }}
                  >
                 
                   <div className="flex flex-col" >
                       <div className="sticky bottom-0 m-auto dark:bg-gray-800 p-5">
                          <div className="flex-1 overflow-auto">
                          {
                              allMessages.map((message) => renderMessage(message))
                          }
                          </div>
                           <div className="text-center p-10">  
                          {
                         
                          isLoading?
                              <ProgressIndicator />
                              :
                              <div/>
                         
                          }
                           </div>
                          <div ref={messageEndRef}></div>
                      </div>
                      
                      <div className="sticky bottom-0 m-auto border-t p-4 bg-white dark:bg-gray-800">
                          <div className="flex items-center space-x-2">
                              <Input
                                  size="medium"
                                  placeholder="Skriv her..."
                                  stretch={true}
                                  value={inputValue}
                                  on_change={({ value }) => {
                                  setInputValue(value)
                                  }}
                                  onKeyUp={(e) => {
                                  if (e.key === "Enter") {
                                      if (activeWorkflow)
                                      storeValueFromWorkflow();
                                      else  
                                      handleRagSendMessage();
                                  }
                                  }}
                              />
                              <Button
                                title="Small sized button with add icon"
                                icon={Microphone}
                                size="medium"
                                on_click={()=>{console.log("her kommer en funksjon som du kan snakke til")}}
                              />
                              <Button text="Send"
                                  on_click={handleRagSendMessage}/>
                          </div>
                      </div>
                    </div>
                </Drawer> */}
              </div>
    )
}
