import { useEffect } from "react";

import { sessionState, useChatSession } from "@chainlit/react-client";
import { Playground } from "./components/playground";
import { MobileChatFirst } from "./components/mobilefirstchat";
import { useRecoilValue } from "recoil";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const userEnv = {};

function App() {
  const { connect } = useChatSession();
  const session = useRecoilValue(sessionState);
  useEffect(() => {
    if (session?.socket.connected) {
      return;
    }
    fetch("http://localhost:80/custom-auth")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        connect({
          userEnv,
          accessToken: `Bearer: ${data.token}`,
        });
      });
  }, [connect]);

  return (
    <Router>
      <Routes>
        <Route path="/" Component={Playground}>
        </Route>
        <Route path="/mobile" Component={MobileChatFirst}>
        </Route>
      </Routes>
    </Router>
    // <>
    //   <div>
    //     <Playground />
    //   </div>
    // </>
  );
}

export default App;